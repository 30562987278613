import { useMotionValueEvent, useScroll } from "framer-motion";
import { useRef } from "react";
import { useBythenNavbarContext } from "../components/BythenNavbar/context";

const useSectionWithNavbar = ({ isBlack }) => {
  const ref = useRef();
  const { scrollYProgress: scrollYSection } = useScroll({
    target: ref,
    offset: ["-40px start", "end start"],
  });

  const { setIsBlack } = useBythenNavbarContext();

  useMotionValueEvent(scrollYSection, "change", (latest) => {
    const isInView = latest >= 0;
    if (isInView) {
      setIsBlack(isBlack);
    }
  });

  return ref;
};

export default useSectionWithNavbar;
