import React, { useCallback } from "react";
import Image from "next/image";
import { ENVIRONMENT } from "@/utils/environment";

const MembersData = [
  {
    name: "Mosu",
    space: "@FireMosu",
    background:
      "Serial founder with 2x successful exits to NYSE: WPP and IDX: GOTO, and member of Spirit DAO.",
    image_url: `${ENVIRONMENT.ASSETS_URL}/landing/member1.png`,
  },
  {
    name: "Otep",
    space: "@diskotep",
    background:
      "Product by day, weeb by night. EX-WPP & GoTo, self-proclaimed professional tourist.",
    image_url: `${ENVIRONMENT.ASSETS_URL}/landing/member4.png`,
  },
  {
    name: "Amankz_",
    space: "@amankz_",
    background:
      "Ex Goto. Gamer at heart, fueled by coffee and noodles, and the unifying force behind the engineering team.",
    image_url: `${ENVIRONMENT.ASSETS_URL}/landing/member3.png`,
  },
  {
    name: "Nagataxyz",
    space: "@nagataxyz",
    background:
      "MBA HKUST-Cornell U, previously worked in Shopee SEA specializing in business development.",
    image_url: `${ENVIRONMENT.ASSETS_URL}/landing/member2.png`,
  },
];

const HOVER_CLASS =
  "hover:shadow-[6px_6px_0px_0px_#0E100F] hover:border-[#0E100F] hover:scale-[1.01] transition-all duration-300";

const MemberItem = ({ name, space, background, image_url, num }) => {
  const onClickCard = useCallback(() => {
    window.open(`https://www.twitter.com/${space}`, "_blank");
  }, [space]);
  return (
    <div
      onClick={onClickCard}
      className={`bg-[#FFFFFF] border border-[#0E100F] min-w-[238px] md:w-[25%] cursor-pointer ${HOVER_CLASS}`}
    >
      <div className="w-full h-auto max-w-full overflow-hidden">
        <Image
          unoptimized
          className="w-full object-cover"
          src={image_url}
          alt="member"
          width={268}
          height={268}
        />
      </div>
      <div className="px-4 py-5">
        <p className="text-sm leading-4 text-[#0E100F] font-medium font-mono">
          CO-FOUNDER #{num}
        </p>

        <div className="flex-grow mt-7 uppercase font-founders font-semibold text-[28px] leading-[28px] md:text-[32px] md:leading-8">
          {name}
        </div>
        <div className="flex-grow text-xs md:text-sm w-[56px] text-[#0E100F99] mt-[6px] md:mt-2">
          {space}
        </div>
        <div className="text-xs mt-4 md:text-base text-[#0E100F]">
          {background}
        </div>
      </div>
    </div>
  );
};
function SectionTeam() {
  return (
    <section className="bg-[#0E100F0D]">
      <div className="py-10 md:py-20 max-w-[1920px] mx-auto">
        <div className="flex flex-col md:flex-row items-center px-4 md:px-16 mb-4 md:mb-12">
          <h3 className="md:-mt-2 w-full md:max-w-[375px] font-founders font-semibold tracking-[0.1px text-[#0E100F] text-[56px] leading-[44px] md:text-[min(10vw,_96px)] md:leading-none">
            OUR TEAM
          </h3>
          <p className="mt-4 md:mt-0 text-sm md:text-lg tracking-[0.2px] w-full md:ml-5 lg:ml-[max(2vw,_150px)] md:max-w-[520px]">
            We&apos;re a group of entrepreneurs, engineers and creatives, united
            by our love for technology and real human connections. Our goal is
            to create innovative solutions that bring people and technology
            closer.
          </p>
        </div>
        <div className="flex gap-4 md:gap-9 overflow-scroll no-scrollbar px-4 md:px-16 py-2 md:py-4">
          {MembersData.map((member, i) => (
            <MemberItem {...member} num={i + 1} key={member.name} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default SectionTeam;
