import Button from "@/components/Button";
import ArrowRightIcon from "@/components/Icon/ArrowRightIcon";
import { ENVIRONMENT } from "@/utils/environment";
import Image from "next/image";
import React from "react";
import useSectionWithNavbar from "../hooks/useSectionWithNavbar";

function SectionProducts() {
  const sectionProductRef = useSectionWithNavbar({ isBlack: true });

  function onOpenBlur() {
    window.open("https://blur.io/eth/collection/bythen-chip", "_blank");
  }
  function onOpenPod() {
    window.open(`${ENVIRONMENT.BASE_URL}/pod`, "_blank");
  }
  return (
    <section
      id="bythen-products"
      ref={sectionProductRef}
      className="bg-[#FFFFFF]"
    >
      <div className="py-10 md:py-[84px] px-4 md:px-16 max-w-[1920px] mx-auto">
        <h3 className="md:-mt-2 w-full max-w-[min(75vw,_900px)] lg:max-w-[975px] uppercase font-founders font-semibold tracking-[0.1px text-[#0E100F] text-[44px] leading-[36px] md:text-[min(10vw,_96px)] md:leading-none">
          Unlocking a realm of unlimited possibilities
        </h3>
        <div className="mt-6 md:mt-12 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-9">
          <div className="bg-[#E9E7DE]">
            <div className="bg-[#FFEAD4] w-full h-[max(5vw,_210px)] md:h-[max(10vw,_365px)]">
              <Image
                unoptimized
                src={`${ENVIRONMENT.ASSETS_URL}/landing/bythen-chip-outlined.webp`}
                width={466}
                height={338}
                className="mx-auto w-auto h-full object-contain object-center"
                alt="bythen chip assets"
              />
            </div>

            <div className="p-4 md:p-8">
              <h4 className="text-[28px] leading-7 md:text-[32px] md:leading-8 font-semibold font-founders uppercase">
                bythen chip
              </h4>
              <p className="text-sm md:text-base tracking-[0.2px] mt-3 md:pr-5">
                Serves as a gateway to the bythen ecosystem, offering various
                privileges such as the bythen Pod, upcoming token airdrop and
                allocation to all future IP collections.
              </p>

              <Button
                onClickButton={onOpenBlur}
                classNames="w-full md:w-auto mt-5"
                type="black"
              >
                bythen chip On Blur{" "}
                <span className="ml-4 w-5">
                  <ArrowRightIcon color="white" />
                </span>
              </Button>
            </div>
          </div>
          <div className="bg-[#E9E7DE] relative">
            <Image
              unoptimized
              src={`${ENVIRONMENT.ASSETS_URL}/landing/badge-collection.svg`}
              width={466}
              height={338}
              className="w-[40%] mb-8 absolute top-0 right-0"
              alt="bythen pod assets"
            />
            <div className="bg-[#DCFFD7] w-full h-[max(5vw,_210px)] md:h-[max(10vw,_365px)]">
              <Image
                unoptimized
                src={`${ENVIRONMENT.ASSETS_URL}/landing/bythen-pods.webp`}
                width={466}
                height={338}
                className="w-auto mx-auto h-full object-contain object-center"
                alt="bythen pod assets"
              />
            </div>
            <div className="p-4 md:p-8">
              <h4 className="text-[28px] leading-7 md:text-[32px] md:leading-8 font-semibold font-founders uppercase">
                bythen POD
              </h4>
              <p className="text-sm md:text-base tracking-[0.2px] mt-3 md:pr-5">
                bythen Pod grants access to Bytes from unique collections and
                unlocks all features of the bythen platform. Start your journey
                with bythen by owning a bythen Pod.
              </p>

              <Button
                // onClickButton={onOpenPod}
                classNames="w-full md:w-auto mt-5"
                type="grey"
                disabled={true}
              >
                <span>Pre-Order Has Ended</span>
                {/* <span className="md:ml-4 w-5">
                  <ArrowRightIcon color="white" />
                </span> */}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionProducts;
