import { motion } from "framer-motion";

const BUTTON_CLASS = {
  black: "bg-[#0E100F] text-white border border-transparent hover:bg-[#FFAF70]",
  white: "bg-white text-[#0E100F] border border-[#0E100F]",
  grey: "bg-[#F5F3F0] text-[#0E100F4D] border border-transparent",
};

const HOVER_CLASS =
  "hover:shadow-[6px_6px_0px_0px_#0E100F] hover:border-[#0E100F] hover:text-[#0E100F] [&_svg_path]:hover:stroke-[#0E100F] hover:scale-[1.01] transition-all duration-300";

function BythenButton({
  children,
  onClickButton,
  classNames,
  type,
  disabled = false,
}) {
  return (
    <motion.button
      className={`px-6 py-[14px] flex md:inline-flex justify-between md:justify-start items-center uppercase text-base md:text-xl leading-5 rounded-none tracking-[0.2px] font-founders font-semibold ${
        disabled ? "cursor-not-allowed" : HOVER_CLASS
      } ${BUTTON_CLASS[type]} ${classNames}`}
      onClick={onClickButton}
      disabled={disabled}
    >
      {children}
    </motion.button>
  );
}

export default BythenButton;
