import Image from "next/image";
import { ENVIRONMENT } from "@/utils/environment";
import Footer from "@/components/Footer";
import { motion } from "framer-motion";

const arrayOfTexts = [
  {
    textComponent: (
      <>
        <span className="relative -mr-[10px] md:-mr-[20px]">
          <span className="invisible tracking-[0.1em]">A</span>
          <span className="absolute top-[7%] h-[45%] w-full left-0">
            <Image
              unoptimized
              fill
              alt=""
              loading="lazy"
              decoding="async"
              data-nimg="fill"
              className="absolute h-full w-full left-0 top-0 right-0 bottom-0 text-transparent"
              src={`${ENVIRONMENT.ASSETS_URL}/landing/section-bottom/join-us-spring.gif`}
            />
          </span>
        </span>
        JOIN US
        <span className="relative mx-1 md:mx-4">
          <span className="invisible tracking-[0.66em]">O</span>
          <span className="absolute top-[26%] h-[55%] w-full left-0">
            <Image
              unoptimized
              fill
              alt=""
              loading="lazy"
              decoding="async"
              data-nimg="fill"
              className="h-full text-transparent rounded-[4px] md:rounded-[12px]"
              src={`${ENVIRONMENT.ASSETS_URL}/landing/section-bottom/join-us.gif`}
            />
          </span>
        </span>
        <span>
          IN SHAPING THE
          <span className="relative ml-2">
            <span className="invisible tracking-[0em]">O</span>
            <span className="absolute top-[20%] h-[54%] w-full left-0">
              <Image
                unoptimized
                fill
                alt=""
                loading="lazy"
                decoding="async"
                data-nimg="fill"
                className="h-full text-transparent"
                src={`${ENVIRONMENT.ASSETS_URL}/landing/section-bottom/the-spring.png`}
              />
            </span>
          </span>
        </span>
      </>
    ),
  },
  {
    textComponent: (
      <>
        F
        <span className="relative">
          <span className="invisible tracking-[0em]">A</span>
          <span className="absolute top-[27%] h-[55%] w-full left-0">
            <Image
              unoptimized
              fill
              alt=""
              loading="lazy"
              decoding="async"
              data-nimg="fill"
              className="absolute h-full w-full left-0 top-0 right-0 bottom-0 text-transparent"
              src={`${ENVIRONMENT.ASSETS_URL}/landing/section-bottom/u-future.png`}
            />
          </span>
        </span>
        TURE OF
        <span className="inline-block ml-[4px]">
          <span className="relative mr-[4px]">
            <span className="invisible tracking-[2.3em]">O</span>
            <span className="absolute top-[1px] md:top-[4px] h-full w-full left-0">
              <Image
                unoptimized
                fill
                alt=""
                loading="lazy"
                decoding="async"
                data-nimg="fill"
                className="h-full text-transparent"
                src={`${ENVIRONMENT.ASSETS_URL}/landing/hero/asset-hero-digital.svg`}
              />
            </span>
          </span>
          IDENTITY
        </span>
      </>
    ),
  },
  {
    textComponent: (
      <>
        START
        <span className="relative ml-2">
          <span className="invisible tracking-[0.1em]">O</span>
          <span className="absolute top-[20%] h-[66%] w-full left-0">
            <Image
              unoptimized
              fill
              alt=""
              loading="lazy"
              decoding="async"
              data-nimg="fill"
              className="h-full text-transparent"
              src={`${ENVIRONMENT.ASSETS_URL}/landing/section-bottom/experience.svg`}
            />
          </span>
        </span>
        EXPERIENCING
      </>
    ),
  },
  {
    textComponent: (
      <>
        T
        <span className="relative mx-[0_-3px_0_-4px] md:-mx-[10px]">
          <span className="invisible tracking-[0em]">O</span>
          <span className="absolute top-[26%] h-[56%] w-full left-0">
            <Image
              unoptimized
              fill
              alt=""
              loading="lazy"
              decoding="async"
              data-nimg="fill"
              className="h-full text-transparent"
              src={`${ENVIRONMENT.ASSETS_URL}/landing/section-bottom/tomorrow.svg`}
            />
          </span>
        </span>
        MORROW&apos;S{" "}
        <span className="inline-block">
          W
          <span className="relative -mx-[5px] md:-mx-3">
            <span className="invisible tracking-[0.5em]">O</span>
            <span className="absolute top-[10%] h-auto w-full left-0">
              <Image
                unoptimized
                width={120}
                height={120}
                alt=""
                loading="lazy"
                decoding="async"
                data-nimg="fill"
                className="w-full h-full text-transparent"
                src={`${ENVIRONMENT.ASSETS_URL}/landing/section-bottom/world.gif`}
              />
            </span>
          </span>
          RLD TODAY
        </span>
      </>
    ),
  },
];

const TextVariants = {
  initial: { opacity: 1, y: "0%", rotate: 0 },
  animate: (index) => ({
    opacity: [0, 0, 1],
    y: ["95%", "95%", "0%"],
    rotate: [5, 5, 0],
    transition: { duration: 0.8, ease: "linear", delay: index * 0.8 },
  }),
};

export default function SectionBottom() {
  return (
    <div
      style={{
        "--title-size": "clamp(28px,calc(8vw),94px)",
        "--title-size-desktop": "clamp(60px,calc(8vw),160px)",
      }}
      className="bg-sonicBlue-300"
    >
      <div className="px-4 md:px-[calc(var(--title-size)*0.6)] py-[calc(var(--title-size)*0.65)]">
        <div className="max-w-[1920px] mx-auto font-founders uppercase text-[length:--title-size] md:text-[length:--title-size-desktop] leading-[calc(var(--title-size)*(50/56))] md:leading-[calc(var(--title-size-desktop)*(130/160))] font-semibold tracking-tight break-words">
          {arrayOfTexts.map((text, index) => {
            return (
              <span
                key={index}
                className="overflow-hidden inline-block uppercase  py-10 -my-10"
              >
                <motion.span
                  custom={index}
                  delay={index}
                  variants={TextVariants}
                  viewport={{
                    once: true,
                  }}
                  whileInView="animate"
                  initial="initial"
                  className="inline-block"
                >
                  {text.textComponent}
                </motion.span>
              </span>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
