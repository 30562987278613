import Image from "next/image";

/**
 *
 * @param {React.ComponentProps<typeof Image> & { wrapperClassName?: string }} props
 * @returns {React.JSX.Element}
 */
const IconElement = ({ wrapperClassName = "", className = "", ...props }) => (
  <span
    className={`inline-block relative w-[24px] h-[24px] md:w-[36px] md:h-[36px] ${wrapperClassName}`}
  >
    <Image
      unoptimized
      className={`w-full h-full ${className}`}
      alt="running-text-image"
      width={48}
      height={48}
      {...props}
    />
  </span>
);

/**
 *
 * @param {{ icon: React.ReactNode; text: React.ReactNode; }} props
 * @returns {React.JSX.Element}
 */
export const SectionIntroduceRunningTextItem = ({ icon, text }) => {
  return (
    <span className="flex items-center">
      {icon}
      <span className="ml-2 mr-6 font-founders font-semibold text-[24px] md:text-[32px] leading-[28px]">
        {text}
      </span>
    </span>
  );
};

SectionIntroduceRunningTextItem.Icon = IconElement;
