import { getIconColor } from "./helper";

export default function ArrowRightIcon({ color }) {
  const iconColor = getIconColor(color);

  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 317">
        <g id="Group 316">
          <path id="Arrow 2" d="M0 7H19" stroke={iconColor} strokeWidth="1.5" />
        </g>
        <path
          id="Vector 133"
          d="M12 1C13.3846 4.125 19 7 19 7C19 7 13.3846 10.5 12 13"
          stroke={iconColor}
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}
